import { useFontWeight } from "@/packages/theme/useFontWeight";
import { useTextAlign } from "@/packages/theme/useTextAlign";
import { useTextColor } from "@/packages/theme/useTextColor";
import cx from "classnames";
import clsx from "clsx";
import { tv, VariantProps } from "tailwind-variants";
import { Box, IBoxProps } from "../Box";

export const useHeading = tv({
  variants: {
    casing: {
      uppercase: clsx(
        useFontWeight({ fontWeight: "semibold" }),
        "tracking-widest uppercase font-sans leading-none"
      ),
    },
  },
});

type HeadingVariants = VariantProps<typeof useHeading> &
  VariantProps<typeof useTextAlign> &
  VariantProps<typeof useFontWeight> &
  VariantProps<typeof useTextColor>;

export type IHeadingProps = {
  as?: "h1" | "h2" | "h3" | "h4";
} & Omit<IBoxProps, "as" | "size"> &
  HeadingVariants;

export const Heading = (props: IHeadingProps) => {
  const { as = "h2", className, casing, ...rest } = props;

  return (
    <Box as={as} className={cx(useHeading({ casing }), className)} {...rest} />
  );
};
