'use client';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoginUserInput } from '@/packages/api/src';
import { loginUserSchema } from '@/packages/api/src/modules/auth/dtos/login-user.schema';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/packages/components/atoms/Form/form';
import { Input } from '@/packages/components/atoms/Input';
import { Heading } from '@/packages/components/atoms/Heading';
import { Flex } from '@/packages/components/atoms/Flex';
import { Button } from '@/packages/components/atoms/Button';
import { useAuth } from '@/packages/components/contexts/auth.context';

export const LoginPage = () => {
  const { login, isLoggingIn, loginError } = useAuth();

  const form = useForm<LoginUserInput>({
    resolver: zodResolver(loginUserSchema),
  });

  const onSubmitHandler = (values: LoginUserInput) => {
    login(values);
  };

  return (
    <Flex
      width="full"
      className="items-center justify-center w-screen h-screen max-w-sm mx-auto"
    >
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmitHandler)}
          className="w-full max-w-md mx-auto space-y-5"
        >
          <Heading>Login</Heading>
          {loginError && loginError}
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input type="password" {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <Button type="submit" isLoading={isLoggingIn}>
            Login
          </Button>
        </form>
      </Form>
    </Flex>
  );
};
